@font-face {
  font-family: 'CustomFont';
  src: url('../public/font/base-font.woff2') format('woff2');
  font-family: 'logo';
  src: url('../public/font/logo.woff2') format('woff2');
  
}
@font-face {
  font-family: 'CustomFont';
  src: url('../public/font/base-font.woff2') format('woff2');
  
}
@font-face {
  font-family: 'CustomFont';
  src: url('../public/font/base-font.woff2') format('woff2');
  
}
@font-face {
  font-family: 'heading';
  src: url('../public/font/heading.woff2') format('woff2');
  
}
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'CustomFont';
    line-height: 1.6;
    overflow-x: hidden;
    width: 100%;
    background-color: #334756 !important;
}
.heading{
  font-family: 'heading';
}
h1,h2,h3,h4,h5,h6{
  font-family: 'heading';
}
.ft-s-logo{font-family: 'logo';}
.y-scroll {
  overflow-y: auto;
}
.x-scroll {
  overflow-x: auto;
}
.no-break {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ft-1 { font-size: 1rem !important; }
.ft-2 { font-size: 1.2rem !important; }
.ft-3 { font-size: 1.4rem !important; }
.ft-4 { font-size: 1.6rem !important; }
.ft-5 { font-size: 1.8rem !important; }
.ft-6 { font-size: 2rem !important; }
.ft-7 { font-size: 2.2rem !important; }
.ft-8 { font-size: 2.4rem !important; }
.ft-9 { font-size: 2.6rem !important; }
.ft-10 { font-size: 2.8rem !important; }
.light-shadow{box-shadow: 1px 2px 1px 2px #b0b5bf;}
.dark-shadow{box-shadow: 1px 2px 1px 2px #350606;}


a{
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  position: relative;
}

h1,h3 {
  position: relative;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #1CA8E3;
}
.h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 3px;
  left: 50%;
  margin-left: -50px;
  background-color: #1CA8E3;
}
.style_2 {
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 10px;
  position: relative;
}
.style_2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #1CA8E3;
}
.style_2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #1CA8E3;
}
a:before{
  content: '';
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
 
  
  
}

a:hover {
 background-position: 0;
}

a:hover::before{
  width: 100%;
} 
.w-0 { width: 0%; }
.w-10 { width: 10%; }
.w-19 { width: 19%; }
.w-18 { width: 18%; }
.w-20 { width: 20%; }
.w-23 { width: 23%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-33 { width: 33.3333%; } /* 1/3 */
.w-40 { width: 40%; }
.w-50 { width: 50%; }
.w-60 { width: 60%; }
.w-66 { width: 66.6667%; } /* 2/3 */
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-90 { width: 90%; }
.w-100 { width: 100%; }
.h-100vh{height: 100vh;}
.height-50px {height: 50px;}
.max-w-90{width: 90%;}
.max-w-100{width: 100%;}
.gap-10{gap:10px;}
.gap-5{gap:5px;}
.wp-50 {width: 50px;}
.wp-100 {width: 100px !important;}
/* Container */
.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

/* Grid System */
.row {
    display: flex;
    flex-wrap: wrap;
}
.row_2 {
  display: flex;
 
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-1 { flex: 0 0 8.33%; max-width: 8.33%; }
.col-2 { flex: 0 0 16.66%; max-width: 16.66%; }
.col-3 { flex: 0 0 25%; max-width: 25%; }
.col-305 { flex: 0 0 30%; max-width: 30%; }
.col-4 { flex: 0 0 33.33%; max-width: 33.33%; }
.col-5 { flex: 0 0 41.66%; max-width: 41.66%; }
.col-6 { flex: 0 0 50%; max-width: 50%; }
.col-7 { flex: 0 0 58.33%; max-width: 58.33%; }
.col-8 { flex: 0 0 66.66%; max-width: 66.66%; }
.col-9 { flex: 0 0 75%; max-width: 75%; }
.col-10 { flex: 0 0 83.33%; max-width: 83.33%; }
.col-11 { flex: 0 0 91.66%; max-width: 91.66%; }
.col-12 { flex: 0 0 100%; max-width: 100%; }
.row-reverse{flex-direction: row-reverse;}
/* Utility Classes */
/* Text Alignment */
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.cursor{
    cursor: pointer;
}
.gap-20{gap: 20px;}
.m-auto { margin: auto !important; }
.m-0 { margin: 0 !important; }
.mt-1 { margin-top: 0.25rem !important; }
.mt-2 { margin-top: 0.5rem !important; }
.mt-3 { margin-top: 1rem !important; }
.mb-1 { margin-bottom: 0.25rem !important; }
.mb-2 { margin-bottom: 0.5rem !important; }
.mb-3 { margin-bottom: 1rem !important; }
.mb-8 { margin-bottom: 3rem !important; }
.header.sticky {
    background-color: #17A2B8; /* Your secondary color */
    color: #333; /* Text color for sticky state */
  }
/* Padding */
.p-0 { padding: 0 !important; }
.pt-1 { padding-top: 0.25rem !important; }
.pt-2 { padding-top: 0.5rem !important; }
.pt-3 { padding-top: 1rem !important; }
.pt-4 { padding-top: 1.5rem !important; }
.pt-8 { padding-top: 5rem !important; }
.pb-8 { padding-bottom: 5rem !important; }
.pb-1 { padding-bottom: 0.25rem !important; }
.pb-2 { padding-bottom: 0.5rem !important; }
.pb-3 { padding-bottom: 1rem !important; }
.pl-1 { padding-left: 0.25rem !important; }
.pl-2 { padding-left: 0.5rem !important; }
.pl-3 { padding-left: 1rem !important; }
.pr-1 { padding-right: 0.25rem !important; }
.pr-2 { padding-right: 0.5rem !important; }
.pr-3 { padding-right: 1rem !important; }
.p-7 { padding: 3rem !important; }
.fs-09 { font-size: 0.9rem !important; }
.fs-08 { font-size: 0.8rem !important; }
.fs-07 { font-size: 0.7rem !important; }
.fs-0 { font-size: 1rem !important; }
.fs-1 { font-size: 1.25rem !important; }
.fs-2 { font-size: 1.5rem !important; }
.fs-3 { font-size: 1.75rem !important; }
.fs-4 { font-size: 2rem !important; }
.fs-5 { font-size: 2.5rem !important; }
.fs-6 { font-size: 3rem !important; }
.fs-7 { font-size: 3.5rem !important; }
/* Display */
.d-block { display: block !important; }
.d-inline-block { display: inline-block !important; }
.d-none { display: none !important; }

/* Flexbox */
.d-flex { display: flex !important; }
.flex-row { flex-direction: row !important; }
.flex-column { flex-direction: column !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-around { justify-content: space-around !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-end{justify-content:end !important;}
.align-items-center { align-items: center !important; }
/* Colors */
.text-wheat{
    color: wheat !important;
}
.text-primary { color: #1791C8 !important; }
.text-secondary { color: #6c757d !important; }
.text-success { color: #28a745 !important; }
.text-danger { color: #dc3545 !important; }

.text-warning { color: #ffc107 !important; }
.text-info { color: #17a2b8 !important; }
.text-light { color: #f8f9fa !important; }
.text-dark { color: #343a40 !important; }

.bg-primary { background :linear-gradient(to right, rgba(23, 145, 200, 1), rgba(28, 168, 227, 1)), url('../public/appmars.webp') no-repeat center center/cover;}
.bg-2{background-color: #e4eff4 !important;}
.bg-linear{background: linear-gradient(to right, #08778C, #00c6ff);}
.bg-third { background:#1b2122}
.bg-white { background: #fff !important; }
.bg-secondary { background-color: #334756 !important; }
.bg-success { background-color: #28a745 !important; }
.bg-danger { background-color: #dc3545 !important; }
.bg-warning { background-color: #ffc107 !important; }
.bg-info { background-color: #17a2b8 !important; }
.bg-light { background-color: #f8f9fa !important; }
.bg-dark { background-color: #020406 !important; }
.bg-light-primary{background-color: #e5eded !important;}
.bg-table-1{background-color: #fdfdfd;}
.bg-table-2{background-color: #e6e6e6;}
.bg-table-3{background-color: cadetblue;}
/* Main Color */
.main-color {
  background-color: #4fa3a2;
}
.bg-complementary-color {
  background-color: #a34f50;
}
.bg-analogous-1 {
  background-color: #4f78a3;
}

.bg-analogous-2 {
  background-color: #4fa36f;
}
.bg-triadic-1 {
  background-color: #a24fa3;
}

.bg-triadic-2 {
  background-color: #a39f4f;
}
.bg-shade-1 {
  background-color: #3f8383;
}

.bg-shade-2 {
  background-color: #2f6262;
}
.bg-tint-1 {
  background-color: #6ab7b6;
}

.bg-tint-2 {
  background-color: #85cbcb;
}
.bg-monochromatic-1 {
  background-color: #5fb5b5;
}

.bg-monochromatic-2 {
  background-color: #3f9191;
}




.rounded { border-radius: 0.25rem; }
.rounded-sm { border-radius: 0.2rem; }
.rounded-lg { border-radius: 0.3rem; }
.rounded-pill { border-radius: 50rem; }
.rounded-left-pill {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}
.rounded-right-pill {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.rounded-3 { border-radius: 3rem; }
.rounded-50 { border-radius: 50%; }

.shadow { box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
.shadow-lg { box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
.text-weight-400{font-weight: 400;}
.text-weight-500{font-weight: 500;}
.text-weight-600{font-weight: 600;}
.text-weight-700{font-weight: 700;}
.text-weight-800{font-weight: 800;}
.text-weight-900{font-weight: 900;}
.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;
}

.table-header {
  display: table-header-group;
  font-weight: bold; 
}

.table-row {
  display: table-row;
}

.table-cell,
.table-cell-heading {
  display: table-cell;
  padding: 8px;
  border: 1px solid #dddddd;
  text-align: left;
}


.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary { color: #fff; background-color: #149c9c; border-color: #149c9c; }
.btn-secondary { color: #fff; background-color: #6c757d; border-color: #6c757d; }
.btn-success { color: #fff; background-color: #28a745; border-color: #28a745; }
.btn-danger { color: #fff; background-color: #dc3545; border-color: #dc3545; }
.btn-warning { color: #212529; background-color: #ffc107; border-color: #ffc107; }
.btn-info { color: #fff; background-color: #17a2b8; border-color: #17a2b8; }
.btn-light { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }
.btn-dark { color: #fff; background-color: #343a40; border-color: #343a40; }

.btn:hover { text-decoration: none; }
.btn:focus, .btn.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
.btn.disabled, .btn:disabled { opacity: 0.65; }

/* Hover and Active Effects */
.hover-primary:hover { color: #0056b3; }
.hover-secondary:hover { color: #495057; }
.hover-success:hover { color: #1e7e34; }
.hover-danger:hover { color: #c82333; }
.hover-warning:hover { color: #7a6b00; }
.hover-info:hover { color: #117a8b; }
.hover-light:hover { color: #b2b2b2; }
.hover-dark:hover { color: #1d2124; }

.active-primary:active { color: #0056b3; }
.active-secondary:active { color: #495057; }
.active-success:active { color: #1e7e34; }
.active-danger:active { color: #c82333; }
.active-warning:active { color: #7a6b00; }
.active-info:active { color: #117a8b; }
.active-light:active { color: #b2b2b2; }
.active-dark:active { color: #1d2124; }
/* Index */
.z-index-1 { z-index: 1; }
.z-index-2 { z-index: 2; }
.z-index-3 { z-index: 3; }
.z-index-4 { z-index: 4; }
.z-index-5 { z-index: 5; }



/* Position */
.position-static { position: static; }
.position-relative { position: relative !important;  }
.position-absolute { position: absolute; }
.position-fixed { position: fixed; }
.position-sticky { position: sticky; }
/* Padding */
.p-1 { padding: 0.25rem !important; }
.p-2 { padding: 0.5rem !important; }
.p-3 { padding: 1rem !important; }
.p-4 { padding: 1.5rem !important; }
.p-5 { padding: 3rem !important; }

/* Margin */
.m-1 { margin: 0.25rem !important; }
.m-2 { margin: 0.5rem !important; }
.m-3 { margin: 1rem !important; }
.m-4 { margin: 1.5rem !important; }
.m-5 { margin: 3rem !important; }
.ml-1 { margin-left: 0.25rem !important; }
.ml-2 { margin-left: 0.5rem !important; }
.ml-3 { margin-left: 1rem !important; }
.mr-1 { margin-right: 0.25rem !important; }
.mr-2 { margin-right: 0.5rem !important; }
.mr-3 { margin-right: 1rem !important; }
/* Opacity */
.opacity-0 { opacity: 0; }
.opacity-25 { opacity: 0.25; }
.opacity-50 { opacity: 0.5; }
.opacity-75 { opacity: 0.75; }
.opacity-100 { opacity: 1; }

/* All Type Position */
.top-0 { top: 0 !important;; }
.right-0 { right: 0 !important; }
.bottom-0 { bottom: 0 !important; }
.left-0 { left: 0 !important;; }
.left-10 { left: 10% !important; }
.left-20 { left: 20% !important; }
.top-60 { top: 60% !important;}
.top-50 { top: 50% !important; }
.top-30 { top: 30% !important;}
.right-50 { right: 50% !important; }
.bottom-50 { bottom: 50% !important; }
.left-50 { left: 50% !important; }

.top-100 { top: 100%; }
.right-100 { right: 100%; }
.bottom-100 { bottom: 100%; }
.left-100 { left: 100%; }

/* Negative Margin */
.mt-n1 { margin-top: -0.25rem !important; }
.mt-n2 { margin-top: -0.5rem !important; }
.mt-n3 { margin-top: -1rem !important; }
.mt-n4 { margin-top: -1.5rem !important; }
.mt-n5 { margin-top: -3rem !important; }

.mb-n1 { margin-bottom: -0.25rem !important; }
.mb-n2 { margin-bottom: -0.5rem !important; }
.mb-n3 { margin-bottom: -1rem !important; }
.mb-n4 { margin-bottom: -1.5rem !important; }
.mb-n5 { margin-bottom: -3rem !important; }

/* Negative Padding */
.pt-n1 { padding-top: -0.25rem !important; }
.pt-n2 { padding-top: -0.5rem !important; }
.pt-n3 { padding-top: -1rem !important; }
.pt-n4 { padding-top: -1.5rem !important; }
.pt-n5 { padding-top: -3rem !important; }

.pb-n1 { padding-bottom: -0.25rem !important; }
.pb-n2 { padding-bottom: -0.5rem !important; }
.pb-n3 { padding-bottom: -1rem !important; }
.pb-n4 { padding-bottom: -1.5rem !important; }
.pb-n5 { padding-bottom: -3rem !important; }
.border { border: 1px solid; }
.border-collapse{border-collapse: collapse;}
.border-top { border-top: 1px solid; }
.border-right { border-right: 1px solid; }
.border-bottom { border-bottom: 1px solid; }
.border-left { border-left: 1px solid; }
.border-primary { border-color: #ddd !important; }
.border-secondary { border-color: #6c757d !important; }
.border-success { border-color: #28a745 !important; }
.border-danger { border-color: #dc3545 !important; }



.svg {
  width: 100px;
  height: 100px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.svg circle {
  stroke-dasharray: 282.74px; /* 2 * Math.PI * radius */
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: #dc3545;
  fill: none;
  animation: countdown 300s linear infinite forwards;
  
}

@keyframes countdown {
  from {
    stroke-dashoffset: 282.74px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}

.border-warning { border-color: #ffc107 !important; }
.border-info { border-color: #17a2b8 !important; }
.border-light { border-color: #f8f9fa !important; }
.border-dark { border-color: #343a40 !important; }
.rounded-top { border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem; }
.rounded-right { border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; }
.rounded-bottom { border-bottom-right-radius: 0.25rem; border-bottom-left-radius: 0.25rem; }
.rounded-left { border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; }
.rounded-circle { border-radius: 50%; }
.rounded-0 { border-radius: 0; }
.border-0 { border-width: 0; }
.border-top-0 { border-top-width: 0; }
.border-right-0 { border-right-width: 0; }
.border-bottom-0 { border-bottom-width: 0; }
.border-left-0 { border-left-width: 0; }
.border-transparent{border: 1px solid transparent !important;}

.text-left { text-align: left !important; }
.text-center { text-align: center !important; }
.text-right { text-align: right !important; }
.text-justify { text-align: justify !important; }
.text-nowrap { white-space: nowrap !important; }

.vertical-align-baseline { vertical-align: baseline !important; }
.vertical-align-top { vertical-align: top !important; }
.vertical-align-middle { vertical-align: middle !important; }
.vertical-align-bottom { vertical-align: bottom !important; }
.vertical-align-inherit { vertical-align: inherit !important; }
.bg-1 {
    
    background-size: cover;
    background-position: center;
    height: 90vh;
  }
  .slide {
    background-size: cover;
    background-position: center;
    height: 90vh;
    

  }
  .bg-1::before {
    content: ""; /* Empty content */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-image: url('./images/bg-1.jpg'); */
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    height: 100vh;
    /* --mask:
      radial-gradient(50.39px at 50% 68.75px,#000 99%,#0000 101%) calc(50% - 50px) 0/100px 51% repeat-x,
      radial-gradient(50.39px at 50% -43.75px,#0000 99%,#000 101%) 50% 25px/100px calc(51% - 25px) repeat-x,
      radial-gradient(50.39px at 50% calc(100% - 68.75px),#000 99%,#0000 101%) calc(50% - 50px) 100%/100px 51% repeat-x,
      radial-gradient(50.39px at 50% calc(100% + 43.75px),#0000 99%,#000 101%) 50% calc(100% - 25px)/100px calc(51% - 25px) repeat-x;
      -webkit-mask: var(--mask);
      mask: var(--mask); */
  }
.login{
    background-color: rgba(103, 15, 227, 0.556);
}

.diagonal {
    --skew-angle: -5deg;
    /* --background: linear-gradient(135deg, #243B55, #17A2B8), url('../public/appmars.webp') no-repeat center center/cover, #FFFFFF; */
    --background: linear-gradient(45deg, rgba(23, 145, 200, 1), rgba(28, 168, 227, 1)), url('../public/appmars.webp') no-repeat center center/cover;


    
    position: relative;
    isolation: isolate;
  }
  
  .diagonal::before {
    content: '';
    background: var(--background);
    position: absolute;
   z-index: -1;
    inset: 0;
    transform: skewY(var(--skew-angle));
    width: 100%;
    font-size: 3rem;
  color: #334756;
  text-align: center;
  
  
  }
  @keyframes animate-letters {
    from {
      opacity: 0;
      transform: translateY(50px) rotate(45deg); /* Initial position with upward translation and rotation */
    }
    to {
      opacity: 1;
      transform: translateY(0) rotate(0); /* Final position with no translation or rotation */
    }
  }
  @keyframes animate-text {
    from {
      opacity: 0;
      transform: skewY(var(--skew-angle)) translateY(50px); /* Move text upwards initially */
    }
    to {
      opacity: 1;
      transform: skewY(var(--skew-angle)); /* Final position */
    }
  }
  .spikes {
    --spike-width: 50px;
    --spike-height: 10px;
    --spike-color: var(--body-bg);
    
    position: relative;
    color: black;
    background: 
      linear-gradient(
        to right, 
        #dbf4f4, 
        #e9f6f1);
  }
  .box {
    
    --mask:
    radial-gradient(51.61px at 50% 72px,#f8f9fa 99%,#f8f9fa 101%) calc(50% - 60px) 0/120px 100%,
    radial-gradient(51.61px at 50% -42px,#f8f9fa 99%,#f8f9fa 101%) 50% 30px/120px 100% repeat-x;
  -webkit-mask: var(--mask);
          mask: var(--mask);
  }
.bg-text{background: rgba(0, 0, 0, 0.5); 
 }

  .box-2 {
    background: linear-gradient(to right, #245d26, #047b9d),url('../public/bg-2.webp') no-repeat center center/cover;;
    --mask: 
  radial-gradient(34.99px at 10% calc(100% - 48px), #000 99%, #0000 101%) calc(20% - 10px) 100%/120px 100%, 
  radial-gradient(34.99px at 20% calc(100% + 18px), #0000 99%, #000 101%) 40% calc(60% - 50px)/120px 100% repeat-x;

    -webkit-mask: var(--mask);
            mask: var(--mask);
    background-size: cover; 
  /* background-blend-mode: overlay; */
  }





  .texx{
    position: relative;
   
}
  .texx::after {
    content: '';
    position: absolute;
    z-index: -1;
    top:10%;
    inset: 0;
    background:linear-gradient(to right, #243B55, #17A2B8) !important; 
     
  -webkit-mask: none; 
  mask: none;
  
  
  }
  .texx::before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    background:linear-gradient(to right, #243B55, #17A2B8) !important; 
    clip-path: polygon(0% calc(100% - 250px), 100% calc(100% - 1200px), 100% 0%, 0% 0%);
  -webkit-mask: none;
  mask: none;}
  
  .spikes::after {
    content: '';
    position: absolute;
    width: 100%;
    height: var(--spike-height);
    background: var(--spike-color);
    -webkit-mask-image: url('./triangle.svg');
    -webkit-mask-size: var(--spike-width) var(--spike-height);
    /* -webkit-mask-repeat: repeat-x; */
    mask-image: url('./triangle.svg');
    mask-size: var(--spike-width) var(--spike-height);
    /* mask-repeat: repeat-x; */
  }
  
  .spikes::before {
    top: 0;
  }
  .spikes::after {
    bottom: 0;
    transform: rotate(.5turn);
  }
  .img-1 {
    width: 100%; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.5s ease-in-out; /* Optional: smooth transition */
  }
  
  .img-1:hover {
    transform: scale(1.1); /* Optional: scale on hover */
  }
  /* nav ul li a:hover,nav ul li button:hover{background-color: #f8f9fa;color:#243B55;} */
  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #3498db;
    z-index: 9999; /* Ensure it's above everything */
    animation: progressAnimation 2s linear infinite;
  }
  input {
    outline: none;
}
/* General styles */

  @media (max-width: 600px) {
  .btn-primary{
  color: #000;
  background-color: white;
  border-color: black;
  }

.md-d-block { display: block !important; }
.md-d-inline-block { display: inline-block !important; }
.md-d-none { display: none !important; }
.md-w-100{flex:none;width: 100% !important; max-width: 100%;}
.md-col-1 { flex: 0 0 8.33%; max-width: 8.33%; }
.md-col-2 { flex: 0 0 16.66%; max-width: 16.66%; }
.md-col-3 { flex: 0 0 25%; max-width: 25%; }
.md-col-4 { flex: 0 0 33.33%; max-width: 33.33%; }
.md-col-5 { flex: 0 0 41.66%; max-width: 41.66%; }
.md-col-6 { flex: 0 0 50%; max-width: 50%; }
.md-col-7 { flex: 0 0 58.33%; max-width: 58.33%; }
.md-col-8 { flex: 0 0 66.66%; max-width: 66.66%; }
.md-col-9 { flex: 0 0 75%; max-width: 75%; }
.md-col-10 { flex: 0 0 83.33%; max-width: 83.33%; }
.md-col-11 { flex: 0 0 91.66%; max-width: 91.66%; }
.md-col-12 { flex: 0 0 100%; max-width: 100%; }
.md-bg-none { background-color: none !important; }
.md-w-50 { width: 50%; }
.md-w-60 { width: 60%; }
.md-w-66 { width: 66.6667%; }
.md-w-70 { width: 70%; }
.md-w-75 { width: 75%; }
.md-w-80 { width: 80%; }
.md-w-90 { width: 90%; }
.md-w-99 { width: 98%; }
.md-w-100 { width: 100%; }
.md-top-90{top:90% !important;}
.md-ft-1 { font-size: 1rem; }
.md-ft-2 { font-size: 1.2rem !important;  }
.md-ft-3 { font-size: 1.4rem !important; }
.md-ft-4 { font-size: 1.6rem !important; }
.md-ft-5 { font-size: 1.8rem !important; }
  }
  @media (min-width: 601px) and (max-width: 1024px) {
    .td-d-block { display: block !important; }
    .td-w-100{flex:none;width: 100% !important; max-width: 100%;}
    .td-w-50{width: 50% !important; max-width: 50%;}
    .td-d-inline-block { display: inline-block !important; }
    .td-d-none { display: none !important; }
    .td-bg-none { background-color: none !important; }
    .td-mt-4 { margin-top: 4rem !important; }
    .td-col-6 { flex: 0 0 48%; max-width: 48%; }
    .td-col-2 { flex: 0 0 15%; max-width: 15%; }
    .md-w-50 { width: 50%; }
    .td-w-99 { width: 99%; }
    .td-top-90{top:90% !important;}
.td-w-60 { width: 60%; }
.td-w-66 { width: 66.6667%; }
.td-w-70 { width: 70%; }
.td-w-75 { width: 75%; }
.td-w-80 { width: 80%; }
.td-w-90 { width: 90%; }
.td-w-100 { width: 100%; }
.td-col-1 { flex: 0 0 8.33%; max-width: 8.33%; }
.td-col-2 { flex: 0 0 16.66%; max-width: 16.66%; }
.td-col-3 { flex: 0 0 25%; max-width: 25%; }
.td-col-4 { flex: 0 0 33.33%; max-width: 33.33%; }
.td-col-5 { flex: 0 0 41.66%; max-width: 41.66%; }
.td-col-6 { flex: 0 0 50%; max-width: 50%; }
.td-col-7 { flex: 0 0 58.33%; max-width: 58.33%; }
.td-col-8 { flex: 0 0 66.66%; max-width: 66.66%; }
.td-col-9 { flex: 0 0 75%; max-width: 75%; }
.td-col-10 { flex: 0 0 83.33%; max-width: 83.33%; }
.td-col-11 { flex: 0 0 91.66%; max-width: 91.66%; }
.td-col-12 { flex: 0 0 100%; max-width: 100%; }
  }

.section{padding-block: min(20vh, 2rem);
  width: calc(min(76.5rem, 90%));
  margin-inline: auto;
  color: #111;}
.section_cant{margin-top: 5em;}
.card{box-shadow: 0.3rem 0.3rem .5rem 0.5rem #d9d9d9}
.card-dark {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}


.card-inner{
  position: relative;
  width: inherit;
  height: 12.75rem;
  background: var(--clr);
  border-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
  border-bottom-right-radius: 0;
  overflow: hidden;
}
:root {
  --clr: #e4eff4 !important;
}
.box{width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 1.25rem;
  overflow: hidden;}
.imgBox{position: absolute;
  inset: 0;}
  .icon {
    position: absolute;
    bottom: -0.375rem;
    right: -0.375rem;
    width: 6rem;
    height: 6rem;
    background: var(--clr);
    border-top-left-radius: 50%;
  }
  .icon::after {
    position: absolute;
    content: "";
    top: -1.25rem;
    right: 0.375rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem var(--clr);
  }
  .icon::before {
    position: absolute;
    content: "";
    bottom: 0.375rem;
    left: -1.25rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem #e4eff4;
  }
  .dark-icon {
    position: absolute;
    bottom: -0.375rem;
    right: -0.375rem;
    width: 6rem;
    height: 6rem;
    background: #020406;
    border-top-left-radius: 50%;
  }
  .dark-icon::after {
    position: absolute;
    content: "";
    top: -1.25rem;
    right: 0.375rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem #020406;
  }
  .dark-icon::before {
    position: absolute;
    content: "";
    bottom: 0.375rem;
    left: -1.25rem;
    background: transparent;
    width: 1.25rem;
    height: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    box-shadow: 0.313rem 0.313rem 0 0.313rem #020406;
  }
.iconBox {
    position: absolute;
    inset: 0.625rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
  }
  .material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-osx-font-smoothing: grayscale;
  }


.bg-or-dark{background: linear-gradient(135deg, rgba(30, 30, 30, 0.8), rgba(50, 50, 50, 0.8)), url('../public/appmars.webp') no-repeat center center/cover;
}
.bg-or-light{background: linear-gradient(135deg, #00BCD4, #9C27B0), url('../public/appmars.webp') no-repeat center center/cover;
}
.order-now-section {
  color: white;
  text-align: center;
  padding: 100px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Content Styling */
.order-now-content {
  max-width: 800px;
  margin: auto;
}

/* Heading Styling */
.order-now-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* Paragraph Styling */
.order-now-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.ord-btn-dark{background: linear-gradient(90deg, rgba(30, 30, 30, 0.8), rgba(50, 50, 50, 0.8));
}
.ord-btn-light{background: linear-gradient(90deg, #2196F3, rgb(0, 195, 255));
}
.order-now-button {
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}


.ord-btn-light:hover {
  background: linear-gradient(90deg, rgba(0,204,255,1), rgb(0, 195, 255));
}
.ord-btn-dark:hover {
  background: linear-gradient(90deg,rgba(50, 50, 50, 0.8),  rgba(30, 30, 30, 0.8));
}

.card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}
